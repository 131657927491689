export default class CustomContext {
  constructor(ctx, width, height, settings) {
    const size = Math.min(width, height);

    /**
     * @type {CanvasRenderingContext2D}
     */
    this.ctx = ctx;
    this.draws = [];
    this.width = width;
    this.height = height;
    this.zoomLevel = 1;
    this.posX = -width / 2 + size / 2;
    this.posY = -height / 2 + size / 2;
    this.settings = settings;
    this.isTransformed = false;
    this.lineWidth = 5;
    this.color = 'black';
    this.fontFamily = 'Arial';
    this.translatePos = { x: 0, y: 0 };
    this.savedTranslates = {};
  }

  setZoomLevel(zoomLevel) {
    this.zoomLevel = Math.min(Math.max(this.settings.minZoom, zoomLevel), this.settings.maxZoom);
  }

  setCanvasSize(width, height) {
    const currentSize = Math.min(this.width, this.height);
    const newSize = Math.min(width, height);

    const posX = ((this.posX + this.width / this.getMapScale() / 2) / currentSize) * newSize - width / this.getMapScale() / 2;
    const posY = ((this.posY + this.height / this.getMapScale() / 2) / currentSize) * newSize - height / this.getMapScale() / 2;

    this.move(posX, posY);
    this.width = width;
    this.height = height;
  }

  getLayer(zIndex) {
    if (!this.draws[zIndex + 10]) {
      this.draws[zIndex + 10] = [];
    }

    return this.draws[zIndex + 10];
  }

  drawLine(positions, zIndex = 0, fixed = false) {
    const currentLayer = this.getLayer(zIndex);

    currentLayer.push({
      type: 'line',
      lineWidth: this.lineWidth,
      lineColor: this.color,
      positions: positions.map((pos) => ({
        x: pos.x + this.translatePos.x,
        y: pos.y + this.translatePos.y,
      })),
      fixed,
    });
  }

  fillLine(positions, zIndex = 0, fixed = false) {
    const currentLayer = this.getLayer(zIndex);

    currentLayer.push({
      type: 'line',
      lineWidth: this.lineWidth,
      fillColor: this.color,
      fill: true,
      positions: positions.map((pos) => ({
        x: pos.x + this.translatePos.x,
        y: pos.y + this.translatePos.y,
      })),
      fixed,
    });
  }

  drawImage(image, options, zIndex = 0, fixed = false) {
    const currentLayer = this.getLayer(zIndex);

    if (!image.done) {
      return;
    }

    currentLayer.push({
      type: 'image',
      image,
      pos: {
        x: options.x + this.translatePos.x,
        y: options.y + this.translatePos.y,
      },
      size: {
        width: options.width,
        height: options.height,
      },
      centered: options.centered,
      sizeFixed: options.sizeFixed,
      rotation: options.rotation,
      fixed,
    });
  }

  drawText(text, options = {}) {
    const currentLayer = this.getLayer(options.zIndex || 0);

    currentLayer.push({
      type: 'text',
      text,
      pos: {
        x: options.pos.x + this.translatePos.x,
        y: options.pos.y + this.translatePos.y,
      },
      textAlign: options.textAlign,
      textBaseline: options.textBaseline,
      fill: false,
      fixed: options.fixed,
      lineWidth: this.lineWidth,
      fillColor: this.color,
      fontStyle: `${this.fontSize}px ${this.fontFamily}`,
      rotation: options.rotation,
    });
  }

  fillText(text, options = {}) {
    const currentLayer = this.getLayer(options.zIndex || 0);

    currentLayer.push({
      type: 'text',
      text,
      pos: {
        x: options.pos.x + this.translatePos.x,
        y: options.pos.y + this.translatePos.y,
      },
      fill: true,
      fixed: options.fixed,
      lineWidth: this.lineWidth,
      fillColor: this.color,
      fontStyle: `${this.fontSize}px ${this.fontFamily}`,
      rotation: options.rotation,
      textAlign: options.textAlign,
      textBaseline: options.textBaseline,
    });
  }

  drawCircle(pos, radius, zIndex = 0, fixed = false) {
    const currentLayer = this.getLayer(zIndex);

    currentLayer.push({
      type: 'circle',
      pos: {
        x: pos.x + this.translatePos.x,
        y: pos.y + this.translatePos.y,
      },
      radius,
      fixed,
      lineWidth: this.lineWidth,
      lineColor: this.color,
    });
  }

  fillRect(options, zIndex = 0, fixed = false) {
    const currentLayer = this.getLayer(zIndex);

    currentLayer.push({
      type: 'rect',
      fill: options.fill ?? true,
      pos: {
        x: options.x + this.translatePos.x,
        y: options.y + this.translatePos.y,
      },
      size: {
        width: options.width,
        height: options.height,
      },
      centered: options.centered,
      sizeFixed: options.sizeFixed,
      rotation: options.rotation,
      fixed,
      lineWidth: this.lineWidth,
      fillColor: this.color,
      lineColor: this.color,
    });
  }

  clearScreen() {
    this.ctx.resetTransform();
    this.ctx.fillStyle = this.settings.backgroundColor;
    this.ctx.fillRect(0, 0, this.width, this.height);
    this.isTransformed = false;
    this.transformMap();
  }

  finish() {
    this.clearScreen();

    this.draws.forEach((layer) => {
      layer.forEach((command) => {
        if (command.fixed) {
          this.clearTransform(true);
        } else {
          this.transformMap(true);
        }

        if (command.lineWidth) {
          this.ctx.lineWidth = command.lineWidth;
        }

        if (command.lineColor) {
          this.ctx.strokeStyle = command.lineColor;
        }

        if (command.fillColor) {
          this.ctx.fillStyle = command.fillColor;
        }

        if (command.fontStyle) {
          this.ctx.font = command.fontStyle;
        }

        switch (command.type) {
          case 'line':
            command.positions.forEach((position, index) => {
              if (index === 0) {
                this.ctx.beginPath();
                this.ctx.moveTo(position.x, position.y);

                return;
              }

              this.ctx.lineTo(position.x, position.y);

              if (index === command.positions.length - 1) {
                if (command.fill) {
                  this.ctx.fill();
                } else {
                  this.ctx.stroke();
                }
              }
            });
            break;

          case 'image': {
            const width = command.size.width / (command.sizeFixed ? this.getMapScale() : 1);
            const height = command.size.height / (command.sizeFixed ? this.getMapScale() : 1);
            const {
              pos,
              centered,
              image,
              rotation,
            } = command;

            if (rotation) {
              this.ctx.translate(pos.x, pos.y);
              this.ctx.rotate(rotation);

              this.ctx.drawImage(
                image,
                centered ? -width / 2 : 0,
                centered ? -height / 2 : 0,
                width,
                height,
              );

              this.clearTransform();

              break;
            }

            this.ctx.drawImage(
              image,
              centered ? pos.x - width / 2 : pos.x,
              centered ? pos.y - height / 2 : pos.y,
              width,
              height,
            );

            break;
          }

          case 'rect': {
            const width = command.size.width / (command.sizeFixed ? this.getMapScale() : 1);
            const height = command.size.height / (command.sizeFixed ? this.getMapScale() : 1);
            const {
              pos,
              centered,
              rotation,
              fill,
            } = command;

            if (rotation) {
              this.ctx.translate(pos.x, pos.y);
              this.ctx.rotate(rotation);

              if (fill) {
                this.ctx.fillRect(
                  centered ? -width / 2 : 0,
                  centered ? -height / 2 : 0,
                  width,
                  height,
                );
              } else {
                this.ctx.strokeRect(
                  centered ? -width / 2 : 0,
                  centered ? -height / 2 : 0,
                  width,
                  height,
                );
              }

              this.clearTransform();

              break;
            }

            if (fill) {
              this.ctx.fillRect(
                centered ? pos.x - width / 2 : pos.x,
                centered ? pos.y - height / 2 : pos.y,
                width,
                height,
              );
            } else {
              this.ctx.strokeRect(
                centered ? pos.x - width / 2 : pos.x,
                centered ? pos.y - height / 2 : pos.y,
                width,
                height,
              );
            }

            break;
          }

          case 'text':
            if (command.textAlign) {
              this.ctx.textAlign = command.textAlign;
            } else {
              this.ctx.textAlign = 'start';
            }

            if (command.textBaseline) {
              this.ctx.textBaseline = command.textBaseline;
            } else {
              this.ctx.textBaseline = 'top';
            }

            
            if (command.rotation) {
              this.ctx.translate(command.pos.x, command.pos.y);
              this.ctx.rotate(command.rotation);

              if (command.fill) {
                this.ctx.fillText(command.text, 0, 0);
              } else {
                this.ctx.strokeText(command.text, 0, 0);
              }

              this.clearTransform();

              break;
            }

            if (command.fill) {
              this.ctx.fillText(command.text, command.pos.x, command.pos.y);
            } else {
              this.ctx.strokeText(command.text, command.pos.x, command.pos.y);
            }
            break;

          case 'circle':
            this.ctx.beginPath();
            this.ctx.arc(command.pos.x, command.pos.y, command.radius, 0, 2 * Math.PI);
            this.ctx.stroke();
            break;

          default:
            break;
        }
      });
    });

    this.draws = [];
  }

  transformMap(canSkip = false) {
    if (canSkip && this.isTransformed) {
      return;
    }

    this.translatePos = { x: 0, y: 0 };
    this.ctx.resetTransform();
    this.ctx.scale(this.getMapScale(), this.getMapScale());

    this.ctx.translate(-this.posX, -this.posY);
    this.isTransformed = true;
  }

  clearTransform(canSkip) {
    if (canSkip && !this.isTransformed) {
      return;
    }

    this.translatePos = { x: 0, y: 0 };
    this.ctx.resetTransform();
    this.isTransformed = false;
  }

  move(newX, newY) {
    const canvasViewportWidth = this.width / this.getMapScale();
    const canvasViewportHeight = this.height / this.getMapScale();

    this.posX = newX;
    this.posY = newY;

    this.posY = Math.min((this.height - canvasViewportHeight) + this.height / 2, this.posY);
    this.posY = Math.max(-(this.height / 2), this.posY);

    this.posX = Math.min((this.width - canvasViewportWidth) + this.width / 2, this.posX);
    this.posX = Math.max(-(this.width / 2), this.posX);
  }

  moveRelative(x, y) {
    this.move(x / this.getMapScale() + this.posX, y / this.getMapScale() + this.posY);
  }

  translate(pos) {
    this.translatePos.x += pos.x;
    this.translatePos.y += pos.y;
  }

  rotate(angle) {
    this.ctx.rotate(angle);
  }

  measureText(text) {
    this.ctx.font = `${this.fontSize}px ${this.fontFamily}`;

    return this.ctx.measureText(text);
  }

  setLineWidth(width, fixed = false) {
    this.lineWidth = width / (fixed ? this.getMapScale() : 1);
  }

  setFontSize(size, fixed = false) {
    this.fontSize = size / (fixed ? this.getMapScale() : 1);
  }

  setFontFamily(family) {
    this.fontFamily = family;
  }

  setColor(color) {
    this.color = color;
  }

  canvasPosToMapPos(pos) {
    return {
      x: this.posX + pos.x / this.getMapScale(),
      y: this.posY + pos.y / this.getMapScale(),
    };
  }

  mapPosToCanvasPos(pos) {
    return {
      x: pos.x - this.posX * this.getMapScale(),
      y: pos.y - this.posY * this.getMapScale(),
    };
  }

  saveCurrentTranslate(name) {
    this.savedTranslates[name] = {
      x: this.translatePos.x,
      y: this.translatePos.y,
    };
  }

  loadTranslate(name, fallback) {
    if (!this.savedTranslates[name] && !fallback) {
      return;
    }

    this.translatePos = this.savedTranslates[name] || fallback;
  }

  getCurrentTranslate() {
    return this.translatePos;
  }

  getMapScale() {
    return this.zoomLevel ** 3;
  }
}
