import $, { get } from 'jlight';
import CustomContext from './CustomContext';
import { drawMap, getCanvasPos } from './mapHelpers';

const mapUpdateFunctions = [];
let mapUpdatesActive = false;

const get2DDistance = (pos1, pos2) => {
  const x = (pos1.x - pos2.x) ** 2;
  const y = (pos1.y - pos2.y) ** 2;

  return Math.sqrt(x + y);
};

const getCenter2DPosition = (pos1, pos2) => ({
  x: (pos1.x - pos2.x) / 2 + pos2.x,
  y: (pos1.y - pos2.y) / 2 + pos2.y,
});

const handleMapUpates = (frameTime) => {
  mapUpdateFunctions.forEach((updateFunc) => {
    updateFunc(frameTime);
  });

  requestAnimationFrame(handleMapUpates);
};

export default ($mapCanvas, config, drawFunction) => {
  const canvas = $mapCanvas.elements[0];
  /**
   * @type {CanvasRenderingContext2D}
   */
  const context = canvas.getContext('2d');
  const timePerFrame = 1000 / config.maxFramerate || 30;
  const { mapManifestPath } = config;
  let lastTouchPos = [];
  let lastTouchDistance = 0;
  let mobileCanvasPos = null;
  let canvasHeight = $mapCanvas.height();
  let canvasWidth = $mapCanvas.width();
  let isMouseDown = false;
  let clickableAreas = [];
  let sliders = [];
  let movedSlider = false;
  let lastFrameTime = 0;
  let hasMoved = false;
  let mapManifest;

  get(mapManifestPath, {
    done: (manifest) => {
      mapManifest = manifest;

      console.log(manifest);
    }
  })

  const customCtx = new CustomContext(context, canvasWidth, canvasHeight, {
    maxZoom: 4.5,
    minZoom: 1,
    backgroundColor: '#002484',
  });

  $mapCanvas.attr('height', canvasHeight);
  $mapCanvas.attr('width', canvasWidth);

  const addClickArea = (info) => {
    clickableAreas.push(info);
  };

  const addSlider = (info) => {
    sliders.push(info);
  };

  const checkSlider = (event) => {
    const {
      x: dragPosX,
      y: dragPosY,
    } = getCanvasPos(event, canvas);

    sliders.forEach(({
      startPos,
      endPos,
      callback,
    }) => {
      const dragsInside = dragPosX > startPos.x
        && dragPosX < endPos.x
        && dragPosY > startPos.y
        && dragPosY < endPos.y;

      if (dragsInside) {
        movedSlider = true;
        const width = endPos.x - startPos.x;
        const percent = ((dragPosX - startPos.x) / width) * 100;

        callback(percent);
      }
    });
  };

  const redraw = (sinceLastFrame) => {
    clickableAreas = [];
    sliders = [];

    try {
      if (mapManifest) {
        drawMap(customCtx, mapManifest);
      }

      drawFunction(customCtx, {
        canvasWidth: Math.min(canvasHeight, canvasWidth),
        canvasHeight: Math.min(canvasHeight, canvasWidth),
        viewportWidth: canvasWidth / customCtx.getMapScale(),
        viewportHeight: canvasHeight / customCtx.getMapScale(),
        windowWidth: canvasWidth,
        windowHeight: canvasHeight,
        sinceLastFrame,
      });
    } catch (err) {
      // TODO: error handling
      console.error(err);
    }
  };

  $mapCanvas.on('wheel', (event) => {
    event.preventDefault();

    const { deltaY } = event;
    const { x: mouseX, y: mouseY } = getCanvasPos(event, canvas);
    const { zoomLevel, posX, posY } = customCtx;

    const canvasX = posX + mouseX / customCtx.getMapScale();
    const canvasY = posY + mouseY / customCtx.getMapScale();

    customCtx.setZoomLevel(zoomLevel + (deltaY * -0.003));

    customCtx.move(canvasX - mouseX / customCtx.getMapScale(), canvasY - mouseY / customCtx.getMapScale());
  });

  $mapCanvas.on('mousedown', (event) => {
    if (event.which === 1) {
      hasMoved = false;
      movedSlider = false;
      event.preventDefault();
      isMouseDown = true;
    }
  });

  $mapCanvas.on('click', (event) => {
    if (hasMoved) {
      return;
    }

    const { x: clickPosX, y: clickPosY } = getCanvasPos(event, canvas);

    context.resetTransform();

    const mapPos = customCtx.canvasPosToMapPos({
      x: clickPosX,
      y: clickPosY,
    });

    clickableAreas.forEach(({
      startPos,
      endPos,
      callback,
      fixed,
    }) => {
      let clicked;

      if (fixed) {
        clicked = clickPosX > startPos.x
          && clickPosX < endPos.x
          && clickPosY > startPos.y
          && clickPosY < endPos.y;
      } else {
        clicked = mapPos.x > startPos.x
          && mapPos.x < endPos.x
          && mapPos.y > startPos.y
          && mapPos.y < endPos.y;
      }

      if (clicked) {
        callback();
      }
    });
  });

  $('body').on('mouseup', (event) => {
    if (event.which === 1) {
      event.preventDefault();
      isMouseDown = false;

      if (!hasMoved) {
        checkSlider(event);
      }
    }
  });

  $('body').on('mousemove', (event) => {
    if (isMouseDown) {
      const {
        movementX,
        movementY,
      } = event;

      if (!hasMoved || movedSlider) {
        checkSlider(event);

        if (movedSlider) {
          return;
        }
      }
      hasMoved = true;

      customCtx.moveRelative(-movementX, -movementY);
    }
  });

  $mapCanvas.on('touchmove', (event) => {
    if (lastTouchPos.length) {
      event.preventDefault();

      if (lastTouchPos.length === 1) {
        // Move with one touch
        const moveX = lastTouchPos[0].screenX - event.targetTouches[0].screenX;
        const moveY = lastTouchPos[0].screenY - event.targetTouches[0].screenY;

        customCtx.moveRelative(moveX, moveY);
      } else {
        // Move/Scale with two touches
        const currentTouchDistance = get2DDistance(
          getCanvasPos(event.targetTouches[0], canvas),
          getCanvasPos(event.targetTouches[1], canvas),
        );

        const posBetweenTouch = getCenter2DPosition(
          getCanvasPos(event.targetTouches[0], canvas),
          getCanvasPos(event.targetTouches[1], canvas),
        );

        const { zoomLevel, posX, posY } = customCtx;

        if (!mobileCanvasPos) {
          mobileCanvasPos = {
            x: posX + posBetweenTouch.x / customCtx.getMapScale(),
            y: posY + posBetweenTouch.y / customCtx.getMapScale(),
          };
        }

        customCtx.setZoomLevel(zoomLevel - (lastTouchDistance - currentTouchDistance) / 200);

        const targetX = mobileCanvasPos.x - posBetweenTouch.x / customCtx.getMapScale();
        const targetY = mobileCanvasPos.y - posBetweenTouch.y / customCtx.getMapScale();

        customCtx.move(targetX, targetY);

        lastTouchDistance = currentTouchDistance;
      }

      lastTouchPos = event.targetTouches;
    }
  }, { passive: false });

  $mapCanvas.on('touchstart', (event) => {
    lastTouchPos = event.targetTouches;

    if (lastTouchPos.length === 2) {
      mobileCanvasPos = null;
      lastTouchDistance = get2DDistance(
        getCanvasPos(lastTouchPos[0], canvas),
        getCanvasPos(lastTouchPos[1], canvas),
      );
    }
  });

  $('body').on('touchend', (event) => {
    lastTouchPos = event.targetTouches;
  });

  global.handleResize = () => {
    canvasHeight = $mapCanvas.height();
    canvasWidth = $mapCanvas.width();
    $mapCanvas.attr('height', canvasHeight);
    $mapCanvas.attr('width', canvasWidth);
    customCtx.setCanvasSize(canvasWidth, canvasHeight);
    redraw(0);
  };

  $(window).on('resize', global.handleResize);

  const updateMap = (frameTime) => {
    const sinceLastFrame = frameTime - lastFrameTime;

    if (sinceLastFrame > timePerFrame) {
      lastFrameTime = frameTime;
      redraw(sinceLastFrame);
    }
  };

  mapUpdateFunctions.push(updateMap);

  if (!mapUpdatesActive) {
    requestAnimationFrame(handleMapUpates);
    mapUpdatesActive = true;
  }

  return {
    redraw,
    addClickArea,
    addSlider,
  };
};
