import CustomContext from "./CustomContext";

const coloredImageCache = {};
const imageCache = {};

global.coloredImageCache = coloredImageCache;

export const getImage = (path) => {
  if (imageCache[path]) {
    return imageCache[path];
  }

  const image = new Image();

  image.src = path;
  image.onload = () => { image.done = true }

  imageCache[path] = image;

  return image;
}

export const getCenterBasedOnPercent = (val1, val2, percent) => ((val2 - val1) * percent) + val1;

export const getPosBasedOnPercent = (pos1, pos2, percent) => ({
  x: getCenterBasedOnPercent(pos1.x, pos2.x, percent),
  y: getCenterBasedOnPercent(pos1.y, pos2.y, percent),
});

// mapsize is global because it needs to be changed for chapter 3 replays
export const getMapPos = (vector3, scaleFactor = 1) => ({
  x: ((global.mapSize / 2 + (vector3.y || vector3.Y)) / global.mapSize) * scaleFactor,
  y: ((global.mapSize - (global.mapSize / 2 + (vector3.x || vector3.X))) / global.mapSize) * scaleFactor,
});

export const getMapRotation = (rot) => (rot.yaw * Math.PI) / 180;

export const getCanvasScale = (input, scale) => (input / 270000) * scale;

export const getCanvasPos = (event, canvas) => {
  const x = event.clientX;
  const y = event.clientY;

  const canvasPos = canvas.getClientRects()[0];

  return {
    x: x - canvasPos.x,
    y: y - canvasPos.y,
  };
};

/**
 *
 * @param {Image} image
 * @param {string} color
 * @returns {HTMLCanvasElement}
 */
export const getColoredImage = (image, color) => {
  if (coloredImageCache[image.src + color]) {
    return coloredImageCache[image.src + color];
  }

  const canvas = document.createElement('canvas');

  if (!image.done) {
    return canvas;
  }

  canvas.height = 100;
  canvas.width = 100;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(image, 0, 0, 100, 100);

  ctx.globalCompositeOperation = 'source-in';

  ctx.fillStyle = color;

  ctx.fillRect(0, 0, 100, 100);
  coloredImageCache[image.src + color] = canvas;

  canvas.done = true;

  return canvas;
};

export const getRotBasedOnPercent = (rot1, rot2, progression) => {
  const lastRot = getMapRotation(rot1);
  const nextRot = getMapRotation(rot2);
  const rotDiff = lastRot - nextRot;
  const otherDirDiff = (Math.PI * 2 - lastRot) + nextRot;
  const otherDirDiff2 = (Math.PI * 2 - nextRot) + lastRot;

  if (Math.abs(rotDiff) > Math.abs(otherDirDiff)) {
    return lastRot + getCenterBasedOnPercent(0, otherDirDiff, progression);
  }

  if (Math.abs(rotDiff) > Math.abs(otherDirDiff2)) {
    return lastRot - getCenterBasedOnPercent(0, otherDirDiff2, progression);
  }

  return getCenterBasedOnPercent(lastRot, nextRot, progression);
};

/**
 *
 * @param {CustomContext} ctx
 * @param {*} mapManifest
 */
export const drawMap = (ctx, mapManifest) => {
  let currentLayer = 0;
  const { imageSize } = mapManifest.meta;

  for (let i = mapManifest.meta.minZoomLevel; i <= mapManifest.meta.maxZoomLevel; i += mapManifest.meta.zoomLevelStep) {
    if (i <= ctx.zoomLevel) {
      currentLayer = i;
    }
  }

  const { imageCount, layerWidth } = mapManifest.layers[currentLayer];

  const size = ctx.height;

  const posPercentX = ctx.posX / size * layerWidth;
  const posPercentY = ctx.posY / size * layerWidth;
  const widthPercent = (ctx.width / ctx.getMapScale()) / size * layerWidth;
  const heightPercent = (ctx.height / ctx.getMapScale()) / size * layerWidth;

  let smallestX = 0;
  let smallestY = 0;

  for (let i = 0; i < imageCount * imageSize; i += imageSize) {
    if (i < posPercentX) {
      smallestX = i;
    }

    if (i < posPercentY) {
      smallestY = i;
    }
  }

  for (let x = smallestX; x < Math.min(posPercentX + widthPercent, layerWidth); x += imageSize) {
    for (let y = smallestY; y < Math.min(posPercentY + heightPercent, layerWidth); y += imageSize) {
      const imagePart = getImage(`/dist/map/${mapManifest.meta.season}/${currentLayer}/${x / imageSize}-${y / imageSize}.png`)

      ctx.drawImage(imagePart, {
        width: imageSize / layerWidth * size,
        height: imageSize / layerWidth * size,
        x: x / layerWidth * size,
        y: y / layerWidth * size,
      }, 0, false);
    }
  }
};
