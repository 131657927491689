import $, { get } from 'jlight';

import './scss/index.scss';

import initMap from './utils/map';
import CustomContext from './utils/CustomContext';
const gridSize = 35;

const getMapSize = (a, scale) => Math.round(a / 262656 * scale);

const zoneSizes = [
  80000,
  40000,
  20000,
  10000,
  5000,
  2500,
  1650,
  1090,
];

$('#zone').on('change', (e) => {
  global.zone = parseInt(e.target.value);
})

$(() => {
  global.mapSize = 262656;
  global.zone = 0;
  global.x = 0;
  global.y = 0;

  let positions = null;

  get('/result.json', {
    done: (data) => {
      positions = data;
    },
    fail: console.log,
  });

  const $canvas = $('canvas');
  let lastFrameTime = Date.now();

  const { addClickArea } = initMap($canvas, {
    maxFramerate: 60,
    mapManifestPath: `/dist/map-${new URLSearchParams(window.location.search).get('season') || 20}.json`,
  }, (context, info) => {
    /**
     * @type {CustomContext}
     */
    const ctx = context;
    ctx.setColor('white');
    ctx.setFontSize(16, false);
    global.ctx = ctx;
    const frameRate = 1000 / (Date.now() - lastFrameTime);
    lastFrameTime = Date.now();

    ctx.fillText(frameRate.toFixed(0), {
      pos: { x: 0, y: 0 },
      zIndex: 10,
      fixed: true,
    });

    if (positions) {
      let total;
      let grid;

      if (positions[global.zone] && positions[global.zone][global.x] && positions[global.zone][global.x][global.y]) {
        ({ total, grid } = positions[global.zone][global.x][global.y]);
      }

      // console.log(positions[global.zone]?.[0]?.[0])

      ctx.setFontSize(16, false);

      ctx.fillText(`zones in square: ${total || 0}`, {
        pos: { x: 0, y: 16 },
        zIndex: 10,
        fixed: true,
      });

      global.total = total;
      global.grid = grid;

      ctx.setColor('purple');

      ctx.setLineWidth(2);

      ctx.drawCircle({
        x: global.x * (info.canvasWidth / gridSize) + info.canvasWidth / gridSize / 2,
        y: global.y * (info.canvasWidth / gridSize) + info.canvasWidth / gridSize / 2,
      }, getMapSize(zoneSizes[global.zone], info.canvasWidth), 3, false)

      for (let x = 0; x < gridSize; x += 1) {
        const okx = x / gridSize * info.canvasWidth;

        for (let y = 0; y < gridSize; y += 1) {
          const oky = y / gridSize * info.canvasWidth;
          let color = '#0009';
          let text = '0%';

          if (grid && grid[x] && grid[x][y]) {
            const percentage = (grid[x][y] / total) || 0;

            color = `#${(Math.floor(percentage * 255)).toString(16).padStart(2, '0').repeat(3)}90`;
            text = (grid[x][y] / total * 100).toFixed(0) + '%';
          }

          ctx.setFontSize(4);
          ctx.setLineWidth(0.8);
          ctx.setColor('black');

          ctx.fillText(text, {
            pos: {
              x: okx + info.canvasWidth / gridSize / 2,
              y: oky + info.canvasWidth / gridSize / 2,
            },
            textAlign: 'center',
            textBaseline: 'middle',
            zIndex: 1,
          });

          ctx.setColor(color);

          ctx.fillRect({
            x: okx - 0.2,
            y: oky - 0.2,
            width: info.canvasWidth / gridSize - 0.4,
            height: info.canvasWidth / gridSize - 0.4,
          }, 0);

          if (!positions[global.zone]?.[x]?.[y]) {
            ctx.setColor('red');
            ctx.setLineWidth(0.5);

            ctx.fillRect({
              x: okx - 0.2,
              y: oky - 0.2,
              width: info.canvasWidth / gridSize - 0.4,
              height: info.canvasWidth / gridSize - 0.4,
              fill: false,
              fillColor: 'red',
            }, 0);
          }

          addClickArea({
            startPos: {
              x: okx,
              y: oky,
            },
            endPos: {
              x: okx + info.canvasWidth / gridSize,
              y: oky + info.canvasWidth / gridSize,
            },
            callback: () => {
              global.x = x;
              global.y = y;
            }
          });
        }
      }
    }

    ctx.finish();
  });
});
